import React from 'react';
import Countdown from '../../components/page/Countdown/Countdown';

const CountdownPage = () => {
	return (
		<>
			<Countdown />
		</>
	);
};

export default CountdownPage;
