export const defaultColors = {
	root: {
		lightBlue: '#cbe8f8',
		primary: '#edf2f8',
		secondaryColor: '#313bac',
		blackColor: '#030303',
		lightGrayColor: '#e4e4e4',
		grayColor: '#505865',
		brownColor: '#46364a',
		whiteColor: '#ffffff',
		purpleColor: '#553A9B',
		redColor: '#D91666',
		orangeColor: '#F26247',
		mediumPurple: '#a838b5',
	},
	gradient: {
		blue: '#2794D3',
		darkBlue: '#415EAD',
		lightBlue: '#3F95CF',
		purple: '#553A9B',
		lightPurple: '#A597CA',
		red: '#D91666',
		pink: '#E385B6',
		orange: '#F26247',
		lightOrange: '#FBB040',
	},
	lightBackground: {
		lightGrayishRed: '#FCE8E3',
		lightGrayishBlue: '#dde8ee',
		lightGrayishYellow: '#fdf7d1',
		paleCyan: '#ECFCFF',
		lightGrayishViolet: '#efe8fa',
		lightGrayishlimeGreen: '#e7fdf3',
		lightGrayishPink: '#f3dde4',
		lightGrayishRed01: '#fcece7',
		lightGray: '#EAEAEA',
		lightPink: '#ed4e6e',
	},
};
